<template>
  <div class="relative flex-grow">
    <div class="flex flex-col gap-4">
      <div v-for="(block, idx) in page?.attributes.blocks" :key="idx" class="w-full">
        <Component :is="getResolvedComponent(block.__component)" :data="block" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useCustomHead } from "~/composables/useCustomHead";
import type { Page } from "~/types/strapi/api/page/content-types/page/page";
import type { Payload } from "~/types/strapi/common/schemas-to-ts/Payload";

const route = useRoute();
useCancelRequestsWhenLeavingPage();

let slug = route.name?.toString();

if (slug === "index") {
  slug = "homepage";
}

const { data: pages } = await useApiFetch<Payload<Page[]>>(`/api/pages?populate=deep&filters[slug]=${slug}`);

if (!pages.value?.data.length) {
  throw createError({ statusCode: 404, statusMessage: "Page Not Found" });
}
const page = computed(() => pages.value?.data[0]);

onMounted(() => {
  if (slug === "datenschutz") {
    page.value?.attributes.blocks.push({
      __component: "block.cookie-declaration",
      id: 99999,
    });
  }
});

const getResolvedComponent = (componentName: string) => {
  switch (componentName) {
    case "block.cookie-declaration":
      return resolveComponent("LazyBlockCookieDeclaration");
    case "block.cta":
      return resolveComponent("LazyBlockCta");
    case "block.faq":
      return resolveComponent("LazyBlockFaq");
    case "block.hero":
      return resolveComponent("LazyBlockHero");
    case "block.map":
      return resolveComponent("LazyBlockMap");
    case "block.testimonial":
      return resolveComponent("LazyBlockTestimonial");
    case "block.simple-content":
      return resolveComponent("LazyBlockSimpleContent");
    case "block.title-text":
      return resolveComponent("LazyBlockTitleText");
    case "block.b2b-hubspot-form":
      return resolveComponent("LazyBlockB2BHubspotForm");
    case "block.customer-logos":
      return resolveComponent("LazyBlockCustomerLogos");
    case "block.list-items-image":
      return resolveComponent("LazyBlockListItemsImage");
    case "block.list-items":
      return resolveComponent("LazyBlockListItems");
    case "block.contact-form":
      return resolveComponent("LazyBlockContactForm");
    case "block.dynamic-hero":
      return resolveComponent("LazyBlockDynamicHero");
    case "block.multi-card":
      return resolveComponent("LazyBlockMultiCard");
    case "block.list-items-cards":
      return resolveComponent("LazyBlockListItemsCards");
    case "block.checkup-table":
      return resolveComponent("LazyBlockCheckupTable");
    case "block.blog-page":
      return resolveComponent("LazyBlockArticlesList");
    case "block.insurance-cards":
      return resolveComponent("LazyBlockInsuranceCards");
    case "block.search-hero":
      return resolveComponent("LazyBlockSearchHero");
    case "block.featured-in":
      return resolveComponent("LazyBlockFeaturedIn");
    case "block.locations":
      return resolveComponent("LazyBlockLocations");
    case "block.reimbursement-info":
      return resolveComponent("LazyBlockReimbursementInfoSection");
    case "block.featured-articles":
      return resolveComponent("LazyBlockFeaturedArticles");
    case "block.feature-highlight-section":
      return resolveComponent("LazyBlockFeatureHighlightSection");
  }
};

const seo = page.value?.attributes.seo;
useCustomHead(seo);
</script>

<style lang="scss" scoped></style>
